import React from "react";
import { Element } from 'react-scroll'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import terminal from "../../../assets/img/terminal.png";
import codeEdit from "../../../assets/img/code-edit.png";
import upload from "../../../assets/img/upload1.png";
import renderRequest from "../../../assets/img/render-request.png";
import createDocApp from "../../../assets/img/gifs/createDocApp.gif";
import editDocApp from "../../../assets/video/edit.mp4";
import push from "../../../assets/img/gifs/push.gif";
import render from "../../../assets/img/gifs/render.gif";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);


const StyledCode = ({children}) => (
  <strong style={{
    backgroundColor: 'rgb(95,95,95)', 
    color: 'rgb(237,95,166)',
    padding: '2px 8px',
  }}>
    {children}
  </strong>
)

const HorizontalTextImageLayout = ({icon, image, video, headerText, contentSide, children}) => {
  const classes = useStyles();
  const style = {
    container: {
      color: 'rgb(153, 153, 153)',
      textAlign: 'left',
      flexDirection: contentSide === 'right' ? 'row-reverse' : null,
      marginBottom: '50px',
    },
    headerContainer: {
      marginBottom: '20px',
    },
    header: {
      display: 'inline-block',
      marginLeft: '20px',
    },
    code: {
      color: 'pink',
      background: 'darkgrey',
    },
    image: {
      backgroundColor: '#272722',
      borderRadius: '5px',
      padding: '20px',
      width: '100%',
    },
  };

  return (<GridContainer style={style.container}>
    <GridItem xs={12} sm={12} md={5}>
      {/* <div style={style.container}> */}
        <div style={style.headerContainer}>
          <img src={icon} />
          <h3 className={classes.title} style={style.header}>{headerText}</h3>
        </div>
        <p>{children}</p>
      {/* </div> */}
    </GridItem>
    <GridItem xs={12} sm={12} md={7}>
      {image ? <img src={image} style={style.image} /> : null}
      {video ? 
        <video loop muted autoPlay width="100%">
          <source 
            src={editDocApp}
            type="video/mp4"
          />
        </video> : 
        null
      }
    </GridItem>
  </GridContainer>);
}

export default function ComponentSection() {
  const classes = useStyles();
  return (
    <Element name="productSection">
      <a id="Components"/>
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <h2 className={classes.title}>Entirely different and incredibly familiar.</h2>
            <h5 className={classes.description}>
              <p>Users expect personalized, context aware documents containing up to date information.  Creating one-off PDF documents that become instantly out of date is not scalable and a huge waste of time.  Basic fill in the blank(i.e. mail merge) functionality just won’t cut it and getting fancy involves cobbling together a ton of VBA that can be a real nightmare to write and maintain.  Creating your layout in the DOM can seem like a great hack until you find yourself struggling to get your layout to look right after translating the DOM to PDF.</p>
              
              <p>This is where Emmo comes in.  Emmo is an end-to-end solution that allows you to leverage the power and flexibility of web app style development while still rendering directly to PDF.  No DOM to PDF translation necessary.  It’s truly the holy grail of dynamic layout and content rendering.</p>
{/*               
              <p>Emmo’s DocApp architecture enables you to author gorgeous documents that reflect the data you provide regardless of complexity.  When you are ready you can simply push your DocApp up Emmo’s cloud infrastructure and begin sending render requests in much the same way a web app is pushed to a web host and served to a browser.</p> */}
              {/* Emmo is an end-to-end solution for creating, authoring, styling and rendering highly dynamic PDF Documents.  
              
              While there have been many approaches to this problem Emmo is the only commercial offering to provide infinite customizability and extremely high developer velocity. */}
            </h5>
            <br />
            <br />
          </GridItem>
          {/* <Workflow /> */}

          <GridItem xs={12} sm={12} md={10}>
              <HorizontalTextImageLayout 
                icon={terminal} 
                image={createDocApp}
                headerText="Create."
                contentSide="left" 
              >
                It couldn't be any easier to get started with the Emmo CLI.  Simply run <StyledCode>emmo create my-next-awesome-docapp</StyledCode> to spin up a new DocApp development environment including a demonstration document to help get you started.
              </HorizontalTextImageLayout>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
              <HorizontalTextImageLayout
                icon={codeEdit}
                video={editDocApp}
                headerText="Edit."
                contentSide="right"
            >
                Authoring DocApps works exactly the way you would expect.  Run the standard <StyledCode>npm start</StyledCode> command from within your DocApp to fire up the development server and auto-open your browser to the live preview.  Edit your code locally using your preferred editor, preview your changes from you browser with live reload, commit changes to your favorite code repo and easily integrate Emmo into your existing CI/CD flow.  You have the flexibility to get as fancy as you like.
              </HorizontalTextImageLayout>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
              <HorizontalTextImageLayout
                icon={upload}
                image={push}
                headerText="Push."
                contentSide="left"
            >
                When you are ready you can push your DocApp up to Emmo’s cloud infrastructure by running <StyledCode>npm run emmoPush</StyledCode> from within your DocApp.  This is analogous to pushing a web app to your web hosting service.  Once pushed your DocApp will be ready to respond to render requests.
              </HorizontalTextImageLayout>
          </GridItem>
          <GridItem xs={12} sm={12} md={10}>
              <HorizontalTextImageLayout
                icon={renderRequest}
                image={render}
                headerText="Render."
                contentSide="right"
            >
                The simplest way to send a render request is using the Emmo CLI.  Just run <StyledCode>emmo render</StyledCode> and you will be guided through a few simple steps to prepare your render request or you can pass the required options to the command to bypass the prompts.  We also provide a graphql API if you would prefer to send render requests this way.  When sending your render request you have the option to pass data to your DocApp.  This is one of the many ways you can make your documents dynamic.
              </HorizontalTextImageLayout>
          </GridItem>
        </GridContainer>
        
        {/* <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Highly Dynamic Documents"
                icon={Chat}
                iconColor="info"
                vertical
              >
                <span>For many use cases a static layout and content works just fine and can be created easily with many off the shelf tools.  But for other more advanced use cases such as weather forecast, currency conversions, user personalized documents, customer quotes/invoices, etc. where the content is dynamic and the layout must be fluid, you need much more control and flexibility.</span>
              </InfoArea>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Flexability"
                icon={VerifiedUser}
                iconColor="success"
                vertical
              >
                <>
                  <span>  Emmo aims to set ourselves apart in 4 ways.</span>
                  <ol>
                    <li>We believe that document authoring should be as flexible and powerful as creating modern web apps which is why we provide an amazing modern developer experience out of the box</li>
                    <li>Foo</li>
                  </ol>
                </>
              </InfoArea>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Focus"
                icon={Fingerprint}
                iconColor="danger"
                vertical
              >
                <span>You need to focus on your users and their requirements.  You don't have time to deal with setting up and maintaining complex infrastructure that your users don't care about.  Emmo helps you stay focused on your users by providing hosting and rendering services for all your DocApps.</span>
              </InfoArea>
            </GridItem>
          </GridContainer>
        </div> */}
        {/* <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Why Emmo?</h2>
            <h4 className={classes.title}>The Standard Approaches</h4>
            <h5 className={classes.description}>
              There are many great open source libraries and even a few decent commercial offerings created to address the issue of creating highly dynamic PDF documents but most fall into one of two categories.  The first is the approach where the user creates a word template with placeholders for dynamic content to be "Mail Merged" in then saves the word document to PDF.This works ok for very simple use cases but more complex use cases often devolve into cobbling together a mess of VBA(visual basic for applications).  VBA is often unwieldy in complex scenarios and can be difficult to find developers for.  The second approach is to create the document in HTML, CSS & JavaScript and convert the document to PDF.  While this approach is much more friendly from a development standpoint it ends up being very finicky to get consistent pixel perfect results.What looks great on the web often times does not translate well to page based PDF documents.There has been some work done to mitigate these problems but ultimately it will always struggle to translate non-native DOM layout to PDF.
            </h5>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <h4 className={classes.title}>Our Approach</h4>
            <h5 className={classes.description}>
              Our approach is to combine the best of both worlds.  Emmo's developer experience is entirely JavaScript and ReactJS based so it is very familiar to a huge number of developers.  It fits right into your standard web app development workflow including version control, linting, live reload, etc.  However, Emmo does not render to the DOM and translate to PDF.  It instead renders your React layout natively to PDF which means nothing gets lost in translation and your documents are consistently pixel perfect.  When you are ready to start rendering PDFs the Emmo service makes it as simple as making an API call and specifying the ID of the document to render.  
            </h5>
          </GridItem>
        </GridContainer> */}
      </div>
    </Element>
  );
}


