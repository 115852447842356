import React from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import Highlight from 'react-highlight'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { monokai } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import loginGif from '../../assets/img/gifs/login.gif';


const styles = {
    card: {
        color: 'white',
        backgroundColor: 'rgb(39, 40, 34)',
        marginTop: 0,
    },
    syntaxHighlighter: {
        margin: '0 10px',
    },
    gif: {
        width: '100%',
    },
}

export default () => {
    return <>
        <p>You don't have any DocApps yet.  Let's fix that</p>

        <span>First install the Emmo cli</span>
        <Card style={styles.card}>
            <SyntaxHighlighter customStyle={styles.syntaxHighlighter} language="bash" style={monokai} >
                {`npm install -g emmo
// or
yarn global add emmo`}
            </SyntaxHighlighter>
        </Card>

        <span>Now that you have the emmo cli installed let's get you logged in.  Use your emmodesign.com login credentials</span>
        <img src={loginGif} style={styles.gif} />
        <br />
        <br />

        <div>Next we will spin up a DocApp developer environment.  Once the environment is ready we can start the server to view the template DocApp</div>
        <Card style={styles.card}>
            <SyntaxHighlighter customStyle={styles.syntaxHighlighter} language="bash" style={monokai} >
                {`emmo create my-new-docapp
cd my-new-docapp
yarn start`}
            </SyntaxHighlighter>
        </Card>

        <div>Lastly, let's push the project to your account.  When it's done you should see a new DocApp on your account</div>
        <Card style={styles.card}>
            <SyntaxHighlighter customStyle={styles.syntaxHighlighter} language="bash" style={monokai} >
                {`yarn emmoPush`}
            </SyntaxHighlighter>
        </Card>

        <p>Congratulations!  You just created your first DocApp.  To learn more about Emmo checkout the documentation <a href={'/docs'}>here</a>.</p>
    </>
}