import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import FeatureSection from "./Sections/FeatureSection.js";
// import ExampleSection from "./Sections/ExampleSection.js";
// import PricingSection from "./Sections/PricingSection.js";
// import TeamSection from "./Sections/TeamSection.js";
// import WorkSection from "./Sections/WorkSection.js";

// import titleImg from "../../assets/img/gifs/login.gif"

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="primary"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/landing-bg.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Beautiful, Highly Dynamic PDF Documents Done Right</h1>
              <h4>
                Your content isn't static.  Why should your documents be?  Create amazingly dynamic documents that present just the right content in the perfect layout every time.
              </h4>
              {/* <h4>
                Your users aren't static.  Why should your documents be?  Create amazingly dynamic documents that present just the right content in the perfect layout every time.
              </h4>
              <h4>
                Your requirements aren't static.  Why should your documents be?  Create amazingly dynamic documents that present just the right content in the perfect layout every time.
              </h4> */}
              
              {/* <h4>
                Create amazingly dynamic documents using native functionality while leveraging the power of the web.  It's like React Native but for documents.
              </h4>
              <h4>
                Everything you need to create amazingly dynamic documents.  We take care of the rest.  
              </h4>
              <h4>
                Create amazingly dynamic documents that present just the right content in the perfect layout.  
              </h4>
              <h4>
                Emmo Design's end-to-end solution allows you to start creating documents quickly,  makes creating sophisticated documents a pleasure.  
                brings development and UI best practices into an intuitive suite of tools, templates and services to help you focus on creating beautiful, sophisticated documents.
              </h4> */}
              <br />
              {/* <Button
                color="danger"
                size="lg"
                href="//#region"
                rel="noopener noreferrer"
              >
                Learn More
              </Button> */}
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={6}>
              <img src={titleImg} alt="title animation with a high level demo of how to create your first DocApp." width="100%"/>
            </GridItem> */}
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection />
          <FeatureSection />
          {/* <ExampleSection /> */}
          {/* <PricingSection /> */}
          {/* <TeamSection /> */}
          {/* <WorkSection /> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
