/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div 
          style={{
            padding: "15px 0",
            margin: "0",
            color: 'lightgray',
          }}
          className={classes.left}
        >
          <a
            target="_blank" 
            href="/Privacy-Policy.pdf"
          >
            Privacy Policy
          </a><br />
          <a
            target="_blank" 
            href="/Terms-of-Use.pdf"
          >
            Terms Of Use
          </a>
        </div>

        <div className={classes.right}>
          &copy; {1900 + new Date().getYear()}, made with{" "}
          <Favorite className={classes.icon} /> by{" "}
          Emmo Inc.
          <br />
          Photo by <a target="_blank" href="https://unsplash.com/@juskteez?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Juskteez Vu</a> on <a target="_blank" href="https://unsplash.com/s/photos/stars?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
