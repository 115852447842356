import { useRecoilState } from 'recoil';
import { Auth } from "aws-amplify";
import userAtom from '../../state/userState';

let userPromise;

// Try to load default from AWS Auth just in case the user is already logged in.
const AuthRoot = () => {
    const [userState, setUserState] = useRecoilState(userAtom);

    // If default value hasn't been set yet and it hasn't already been requested get default value
    if (userState === null && !userPromise) {
        // Check if user is already authenticated
        userPromise = Auth.currentAuthenticatedUser();
        
        userPromise
            .then(userResponse => {
                // User is authenticated
                if (userResponse.signInUserSession.idToken.jwtToken) {
                    // setUserState(user)
                    setUserState({
                        id: userResponse.attributes.sub,
                        email: userResponse.attributes.email,
                        emailVerified: userResponse.attributes.email_verified,
                        idToken: userResponse.signInUserSession.idToken.jwtToken,
                    });
                    // return Auth.userAttributes(user);
                    return Auth.currentUserInfo();
                }
                // User is not authenticated
                else {
                    setUserState(false);
                }
            })
            // .then(userAttrs => {
            //     console.log('userAttrs: ', userAttrs);
            // })
            // User is not authenticated
            .catch(() => {
                setUserState(false)
            });
    }

    return null;
}


export default AuthRoot;