import React from "react";
import { Element } from 'react-scroll'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import FastForwardIcon from '@material-ui/icons/FastForward';
import ExposurePlus1Icon from '@material-ui/icons/ExposurePlus1';
import CachedRoundedIcon from '@material-ui/icons/CachedRounded';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import Chat from "@material-ui/icons/Chat";
import {
  Sprout,
  FileDocumentEdit,
  Sofa,
  NewspaperVariant,
  Console,
  CurrencyUsdOff
} from 'mdi-material-ui'

import AssessmentIcon from '@material-ui/icons/Assessment';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function FeatureSection() {
  const classes = useStyles();
  return (
    <Element name="featureSection" >
      <a id="Features"/>
      <div
        className = {
          classes.section
        }
      >
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Everything you need right out of the box.</h2>
            <h5 className={classes.description}>
              All the setup and configuration of new development environments, document boilerplate, infrastructure, etc. at scale can be a huge time suck.  With Emmo's end-to-end solution you can immediately focus on your users.
            </h5>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Get Started Fast"
                icon={FastForwardIcon}
                iconColor="info"
                vertical
              >
                <span> With Emmo 's CLI you are just 1 command away from starting your next document.  Check out our <a href="/docs/#/pages/quickStart/index">Quick Start Guide</a> for details.</span>
              </InfoArea>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Great out of the gate"
                icon={ExposurePlus1Icon}
                iconColor="success"
                vertical
              >
                <span>We start you off with our demonstration document so you can easily get a feel for how Emmo DocApps are composed, how our components display by default and so you can start seeing results immediately.</span>
              </InfoArea>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Live Reload"
                icon={CachedRoundedIcon}
                iconColor="danger"
                vertical
              >
                <span>Ain't nobody got time to reload.  Just make a change, save and voilà. Your developer preview is updated in an instant.  No setup required.</span>
              </InfoArea>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Design Locally"
                // description="You work best in the comfort of your own developer environment.  You have mastered your favorite editor and have all the best plugins that make you most efficient.  Emmo allows you to develop locally so you can stay right in your comfort zone."
                icon={Sofa}
                iconColor="info"
                vertical
              >
                <span>You work best in the comfort of your own developer environment.  You have mastered your favorite editor and have all the best plugins that make you most efficient.  Emmo allows you to develop locally so you can stay right in your comfort zone.</span>
              </InfoArea>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Ahead of the Learning Curve"
                icon={TrendingUpIcon}
                iconColor="success"
                vertical
              >
                <span>Emmo documents are written entirely in JavasScript so getting up and productive is a breeze.  No difficult, poorly documented languages/APIs to learn.  Check out our <a href="/docs/#/pages/quickStart/index">Quick Start Guide</a> for details.</span>
              </InfoArea>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Modern"
                icon={WhatshotIcon}
                iconColor="danger"
                vertical
              >
                <span>Don’t get stuck with ancient libraries.  Emmo leverages the modern <a href="https://www.reactjs.org" target="_blank" rel="noopener noreferrer">ReactJS</a> library for defining your layout logic.  <a href="/docs/#/pages/firstDocApp/index?id=documentjsx">Click here</a> to see how beautifully elegant document composition can be.</span>
              </InfoArea>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Community"
                icon={Chat}
                iconColor="info"
                vertical
              >
                <span>We love JavaScript!  The community is one of the most vibrant and active around.  This means if you have a question odds are the community has already figured it out.</span>
              </InfoArea>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="A Whole Ecosystem"
                icon={Sprout}
                iconColor="success"
                vertical
              >
                <span>Don’t get stuck reinventing the wheel. Using Emmo allow you leverage a whole world of great NodeJS packages(i.e. <a href="https://momentjs.com/">MomentJS</a>, <a href="https://lodash.com/">Lodash</a>, <a href="https://github.com/apollographql/apollo-client" >Apollo</a>, etc.) available at your fingertips to help you create that amazing document in no time.</span>
              </InfoArea>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Native"
                icon={FileDocumentEdit}
                iconColor="danger"
                vertical
              >
                <span>Avoid the DOM to PDF translation headaches.  While Emmo may be very familiar to web developers it is designed to render your layout directly to the <a href="https://d35j6yark6so3m.cloudfront.net/docs/#/pages/firstDocApp/index?id=philosophy">native PDF document</a> format so everything is consistently pixel perfect.</span>
              </InfoArea>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Layout Components"
                icon={NewspaperVariant}
                iconColor="info"
                vertical
              >
                <span>Skip the boiler plate.  We provide <a href="/docs/#/pages/highLevelComponents/index">a whole host of components</a> covering the most common layout features for you to compose together to create your unique documents.</span>
              </InfoArea>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="CLI"
                icon={Console}
                iconColor="success"
                vertical
              >
                <span>You can think of the <a href="/docs/#/pages/cli/index">Emmo Command Line Interface(CLI)</a> as your personal assistant. It's there to help you manage all aspects of your Emmo DocApps(i.e. creating, viewing, deleting, rendering, etc.).</span>
              </InfoArea>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Get Started for Free"
                icon={CurrencyUsdOff}
                iconColor="danger"
                vertical
              >
                {/* <span><a href="/login">Take Emmo for a spin.</a>  It's totally free to get started with the developer edition, no credit card required.</span> */}
                <span>Take Emmo for a spin.  The developer edition is totally free and provides unlimited DocApps and Renders, no credit card required.</span>
              </InfoArea>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </Element>
  );
}
