/*eslint-disable*/
import React from "react";
import { scroller } from 'react-scroll'
import { useRecoilState } from 'recoil';
import { Auth } from "aws-amplify";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link, useRouteMatch } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// State
import userAtom from '../../state/userState';

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

const ScrollButton = ({scrollTo, label}) => {
	const classes = useStyles();
	const isRoot = useRouteMatch({
		path: '/',
		exact: true
	});

	const isIndex = useRouteMatch({
		path: '/index',
		exact: true
	});

	const isLandingPage = isRoot || isIndex;

	return (
		<Button
			color="transparent"
			className={classes.navLink}
			onClick = {() => isLandingPage ? 
				scroller.scrollTo(scrollTo, {
					duration: 800,
					delay: 0,
					smooth: 'easeInOutQuart'
				}) :
				null
			}
		>
			{isLandingPage ? 
				label : 
				<Link 
					to={`/index#${label}`}
					style={{
						color: 'white'
					}}
				>
					{label}
				</Link>
			}
		</Button>
	);
};

export default function HeaderLinks(props) {
	const classes = useStyles();
	const [user, setUserState] = useRecoilState(userAtom);
	const isAdmin = useRouteMatch({
		path: '/admin',
		exact: true
	});

	const isGraphiql = useRouteMatch({
		path: '/graphiql',
		exact: true
	});

	const handleLogout = async () => {
		try {
			await Auth.signOut();
			setUserState(false);
		} catch (error) {
			console.log('error signing out: ', error);
		}
	}

	return (
		<List className={classes.list}>
			{!isAdmin && !isGraphiql ? 
				<ListItem className={classes.listItem}>
					<ScrollButton scrollTo={'productSection'} label={'Product'} />
					<ScrollButton scrollTo={'featureSection'} label={'Features'} />
					{/* <ScrollButton scrollTo={'exampleSection'} label={'Examples'} /> */}
					{/* <ScrollButton scrollTo={'pricingSection'} label={'Pricing'} /> */}
					<a href="/docs" style={{color: 'inherit'}} >
								<Button
									color="transparent"
									className={classes.navLink}
								>
									Docs
								</Button>
							</a>
				</ListItem> :
				null
			}
			<ListItem 
				className={classes.listItem}
				style={{
					margin: '5px 10px',
					// backgroundColor: 'lightGreen',
					height: '40px',
					borderRight: '1px solid white'
				}}
			>
				{null}
			</ListItem>
			<ListItem className={classes.listItem}>
				{ user ?
					<>
						{!isAdmin ? 
							<Link to="/admin" style={{color: 'inherit'}} >
								<Button
									color="transparent"
									className={classes.navLink}
								>
									Admin
								</Button>
							</Link> :
							null
						}
						{!isGraphiql ? 
							<Link to="/graphiql" style={{color: 'inherit'}} >
								<Button
									color="transparent"
									className={classes.navLink}
								>
									GraphiQL
								</Button>
							</Link> :
							null
						}
						<Button
							onClick={handleLogout}
							color="transparent"
							className={classes.navLink}
						>
							Log Out
						</Button>
					</> :
					// <Link to = "/login" style={{color: 'inherit'}} >
						<Button
							color="transparent"
							className={classes.navLink}
						>
							Coming Soon!
						</Button>
					// </Link>
				}
			</ListItem>
		</List>
	);
}
