import React from "react";
import { useRecoilState } from 'recoil';
import { Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import userAtom from '../../state/userState';

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/landing-bg.jpg";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");  
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [verificationCode, setVerificationCode] = React.useState('');

  const [registerUser, setRegisterUser] = React.useState(false);
  const [registerUserFailure, setRegisterUserFailure] = React.useState(null);
  const [forgotPasswordForm, setShowForgotPasswordForm] = React.useState(false);
  const [passwordUpdateForm, setPasswordUpdateForm] = React.useState(false);
  
  
  const [authFailure, setAuthFailure] = React.useState(null);
  const [resetRequestFailure, setResetRequestFailure] = React.useState(null);
  const [resetFailure, setResetFailure] = React.useState(null);
  const [verificationFailure, setVerificationFailure] = React.useState(null);

  const [userState, setUserState] = useRecoilState(userAtom);

  React.useEffect(() => {
    const timeout = setTimeout(function() {
      setCardAnimation("");
    }, 700);

    return () => clearTimeout(timeout);
  })

  const classes = useStyles();
  const { ...rest } = props;

  // Logs the user into Emmo and adds the user's details to local state
  const handleLogin = async (e) => {
    // console.log('Logging In');
    e.preventDefault();
    try{
      const userResponse = await Auth.signIn(username, password);
      // console.log('Logged in User: ', userResponse);
      
      resetLoginForm();

      setUserState({
        id: userResponse.attributes.sub,
        email: userResponse.attributes.email,
        emailVerified: userResponse.attributes.email_verified,
        idToken: userResponse.signInUserSession.idToken.jwtToken,
      });
    }
    catch(error) {
      console.log('error signing in', error);
      if (error.name === "UserNotConfirmedException") {
        // const user = await Auth.currentUserInfo();
        // const user = await Auth.resendSignUp(username);
        // console.log('user info: ', user);
        setShowForgotPasswordForm(false);
        setRegisterUser(true);
        setPasswordUpdateForm(false);
      }
      else {
        setAuthFailure(error);
      }
    }
  };

  // Handles the initial registration of the user with cognito
  const handleRegister = async () => {
    // console.log('Registered');

    try {
      // const registerResponse = 
      await Auth.signUp({
        username,
        password,
        attributes: {
          email: username,
        }
      });

      // console.log(registerResponse);
      setShowForgotPasswordForm(false);
      setRegisterUser(true);
      setPasswordUpdateForm(false);
    } catch (error) {
      console.log('error signing up:', error);
      setRegisterUserFailure(error);
    }
  };

  // Handles verifying the user's email address
  // Sends the verification code spcified by the user back to cognito 
  // to verify.  If matched the user will be marked verified.
  const handleVerification = async (e) => {
    e.preventDefault();
    e.persist();
    console.log('Verifying...', username, verificationCode);
    try {
      const verifyResponse = await Auth.confirmSignUp(username, verificationCode);
      console.log('verifyResponse: ', verifyResponse);
      await handleLogin(e);

      console.log('userState: ', userState);
    }
    catch(error) {
      console.log('Verification Failed: ', error);
      setVerificationFailure(error);
    }
  }

  // Sets the login form back to it's original state
  const resetLoginForm = () => {
    setShowForgotPasswordForm(false);
    setRegisterUser(false);
    setPasswordUpdateForm(false);
  };

  // Sets the login form to forgot password state which allows the user to 
  // reset their password
  const showForgotPasswordForm = () => {
    setShowForgotPasswordForm(true);
    setRegisterUser(false);
    setPasswordUpdateForm(false);
    
    setResetRequestFailure(null);
    setResetFailure(null);
  };

  // Handles resetting the user's password to the specified value
  const handleForgotPasswordRequest = async () => {
    console.log('Switch to forgot password');
    try {
      await Auth.forgotPassword(username);
      setShowForgotPasswordForm(false);
      setRegisterUser(false);
      setPasswordUpdateForm(true);
      setVerificationCode('');
      setPassword('');
    }
    catch(error) {
      console.log('Error sending forgot password code: ', error);
      setResetRequestFailure(error);
    }
  }

  // Handles changing user's password to a new value
  const handlePasswordUpdateForm = async (e) => {
    // console.log('Sending reset request');
    try{
      await Auth.forgotPasswordSubmit(username, verificationCode, password);
      await handleLogin(e);
    }
    catch(error) {
      console.log('password reset error: ', error);
      setResetFailure(error);
    }
  }

  // Handles telling cognito to send a verification code for the specified user
  const handleResendVerificationCode = async () => {
    try {
      await Auth.resendSignUp(username);
    }
    catch(error) {
      console.log('Error resending verification code: ', error);
    }

  }

  return (<>
    {/* Redirect to admin when user is authenticated */}
    {
      userState && userState.idToken ?
      <Redirect to="/admin" /> :
      <div>
        <Header
          absolute
          color="primary"
          rightLinks={<HeaderLinks />}
          {...rest}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                {/* 
                    Show the Login/Register form
                    If user has registered or tried to log in but hasn't verified their email address prompt the user for the verification code
                */}
                {registerUser || (userState && userState.userConfirmed === false) ?
                  // Verification Code Card
                  <Card className={classes[cardAnimaton]}>
                    <form className={classes.form}>
                      <CardHeader color="primary" className={classes.cardHeader}>
                        <h4>Verify Email</h4>
                      </CardHeader>
                      <CardBody>
                        { verificationFailure ?
                            <p style={{color: 'red'}}>{verificationFailure.message}</p> :
                            null
                        }
                        <CustomInput
                          labelText="Verification Code"
                          id="verification-code"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            endAdornment: (
                              <InputAdornment position="end">
                                <VerifiedUserIcon className={classes.inputIconsColor} />
                              </InputAdornment>
                            ),
                            onChange: event => setVerificationCode(event.target.value),
                            value: verificationCode
                          }}
                        />
                        <Button
                          variant={"text"} 
                          style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            color: 'blueviolet',
                            cursor: 'pointer',
                          }} 
                          onClick={handleResendVerificationCode}
                        >
                          Resend Verification Code
                        </Button>
                      </CardBody>
                      <CardFooter className={classes.cardFooter}>
                        <Button color="success" size="lg" type="submit" onClick={handleVerification}>
                          Verify
                        </Button>
                      </CardFooter>
                    </form>
                  </Card> :
                  
                  forgotPasswordForm ?
                  // Forgotten password card
                  <Card className={classes[cardAnimaton]}>
                    <form className={classes.form}>
                      <CardHeader color="primary" className={classes.cardHeader}>
                        <h4>Forgot Password?</h4>
                      </CardHeader>
                      <CardBody>
                        { resetRequestFailure ?
                            <p style={{color: 'red'}}>User not found</p> :
                            null
                        }
                        <CustomInput
                          labelText="Email..."
                          id="email"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "email",
                            endAdornment: (
                              <InputAdornment position="end">
                                <Email className={classes.inputIconsColor} />
                              </InputAdornment>
                            ),
                            onChange: event => setUsername(event.target.value)
                          }}
                        />
                      </CardBody>
                      <CardFooter className={classes.cardFooter}>
                        <Button 
                          color="secondary"
                          size="lg"
                          onClick={resetLoginForm} >
                            Cancel
                        </Button>
                        <Button 
                          color="success"
                          size="lg"
                          onClick={handleForgotPasswordRequest} >
                            Send Reset Code
                        </Button>
                      </CardFooter>
                    </form>
                  </Card> :

                  passwordUpdateForm ?
                  // Reset password card
                  <Card className={classes[cardAnimaton]}>
                    <form className={classes.form}>
                      <CardHeader color="primary" className={classes.cardHeader}>
                        <h4>Reset Password</h4>
                      </CardHeader>
                      <CardBody>
                        {
                          resetFailure && resetFailure.name === 'InvalidParameterException' ?
                            <p style={{color: 'red'}}>Invalid Password.  Password must be at least 8 characters</p> :
                            resetFailure ?
                              <p style={{color: 'red'}}>{resetFailure.message}</p> :
                              null
                        }
                        <CustomInput
                          labelText="Verification Code"
                          id="verification-code"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            endAdornment: (
                              <InputAdornment position="end">
                                <VerifiedUserIcon className={classes.inputIconsColor} />
                              </InputAdornment>
                            ),
                            onChange: event => setVerificationCode(event.target.value),
                            value: verificationCode
                          }}
                        />
                        <CustomInput
                          labelText="Password"
                          id="pass"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "password",
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputIconsColor}>
                                  lock_outline
                                </Icon>
                              </InputAdornment>
                            ),
                            autoComplete: "off",
                            onChange: event => setPassword(event.target.value)
                          }}
                        />
                      </CardBody>
                      <CardFooter className={classes.cardFooter}>
                        <Button
                        color="success"
                        size="lg"
                        onClick={handlePasswordUpdateForm} >
                          Reset Password
                        </Button>
                      </CardFooter>
                    </form>
                  </Card> :

                  // Login Card
                  <Card className={classes[cardAnimaton]}>
                    <form className={classes.form} onSubmit={handleLogin}>
                      <CardHeader color="primary" className={classes.cardHeader}>
                        <h4>Login</h4>
                      </CardHeader>
                      <CardBody>
                        { authFailure ?
                            <p style={{color: 'red'}}>{authFailure.message}</p> :
                            null
                        }
                        {
                          registerUserFailure ?
                            <p style={{color: 'red'}}>{registerUserFailure.message}</p> :
                            null
                        }
                        <CustomInput
                          labelText="Email..."
                          id="email"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "email",
                            autoFocus: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <Email className={classes.inputIconsColor} />
                              </InputAdornment>
                            ),
                            onChange: event => setUsername(event.target.value)
                          }}
                        />
                        <CustomInput
                          labelText="Password"
                          id="pass"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "password",
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputIconsColor}>
                                  lock_outline
                                </Icon>
                              </InputAdornment>
                            ),
                            autoComplete: "off",
                            onChange: event => setPassword(event.target.value)
                          }}
                        />
                        <Button
                          variant={"text"}
                          style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            color: 'blueviolet',
                            cursor: 'pointer',
                          }} 
                          onClick={showForgotPasswordForm} 
                        >
                          Forgot Password?
                        </Button>
                      </CardBody>
                      <CardFooter className={classes.cardFooter}>
                        <Button 
                          color="success"
                          size="lg"
                          type="submit"
                          onClick={handleLogin} 
                        >
                          Login
                        </Button>
                        <Button color="primary" size="lg" onClick={handleRegister}>
                          Register
                        </Button>
                      </CardFooter>
                    </form>
                  </Card>
                }
              </GridItem>
            </GridContainer>
          </div>
          <Footer whiteFont />
        </div>
      </div>
    }
  </>);
}


