import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import { RecoilRoot, useRecoilState } from "recoil";
import { createBrowserHistory } from "history";
import ReactGA from 'react-ga';
import { Router, Route, Switch } from "react-router-dom";
import Amplify from "aws-amplify";
import Auth from './components/Auth/Auth';
import { ApolloProvider } from '@apollo/react-hooks';
import client from './apollo';

import "assets/scss/material-kit-react.scss?v=1.8.0";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";
import AdminPage from "views/AdminPage/AdminPage.js";
import GraphiqlPage from "views/GraphiqlPage/GraphiqlPage.js";
import LoginPage from "views/LoginPage/LoginPage.js";

// Recoil User State
import userAtom from './state/userState';

// Initialize Google Analytics
// console.log('envVar', process.env.REACT_APP_GOOGLE_ANALYTICS);
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);


Amplify.configure({
  "aws_project_region": process.env.REACT_APP_AWS_PROJECT_REGION,
  "aws_cognito_region": process.env.REACT_APP_AWS_COGNITO_REGION,
  "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOLS_ID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  "oauth": process.env.REACT_APP_OAUTH,
  "aws_appsync_graphqlEndpoint": process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
  "aws_appsync_region": process.env.REACT_APP_AWS_APPSYNC_REGION,
  "aws_appsync_authenticationType": process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATIONTYPE,
});

var hist = createBrowserHistory();
hist.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

// console.log('Client: ', client);

const ApolloApp = () => {
  const [userState] = useRecoilState(userAtom);

  return (
    <ApolloProvider client={client(userState)} >
      <Router history={hist}>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/admin" component={AdminPage} />
          <Route path="/graphiql" component={GraphiqlPage} />
          <Route path="/" component={LandingPage} />
        </Switch>
      </Router>
    </ApolloProvider>
  );
}

const App = () => {
  useEffect(() => {
    // Send initial page load event to GA
    ReactGA.pageview(window.location.pathname);
    // console.log('pageView', window.location.pathname);
  });

  return (
    <RecoilRoot>
      <Auth />
      <ApolloApp />
    </RecoilRoot>
  );
};

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
