import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';

// Creates apollo client
export default (userState) => new ApolloClient({
    link: ApolloLink.from([
        onError(({
            graphQLErrors,
            networkError
        }) => {
            if (graphQLErrors) {
                graphQLErrors.forEach(({
                        message,
                        locations,
                        path
                    }) =>
                    console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
                );
            }
            if (networkError) console.log(`[Network error]: ${networkError}`);
        }),
        new HttpLink({
            uri: process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
            headers: {
                Authorization: userState ? userState.idToken : 'notAuthenticated',
            },
        })
    ]),
    cache: new InMemoryCache()
});