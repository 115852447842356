import React from "react";
import {
  // atom,
  // selector,
  useRecoilState,
  // useRecoilValue,
  // useSetRecoilState,
} from 'recoil';
import { Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";
import {Elements, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
// @material-ui/core components
import moment from 'moment';
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GettingStarted from './GettingStarted';

import stripePromise from '../../stripe';

import userAtom from '../../state/userState';
import adminPageAtom from '../../state/adminPageState';

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/landing-bg.jpg";

const useStyles = makeStyles(styles);

// 
const getUserResponseSubset = (({
  attributes,
  authenticationFlowType,
  client,
  keyPrefix,
  preferredMFA,
  signInUserSession,
  userDataKey,
  username
}) => ({
  attributes,
  authenticationFlowType,
  client,
  keyPrefix,
  preferredMFA,
  signInUserSession,
  userDataKey,
  username
}));

const GET_CUSTOMER = gql `
  query GetCustomer {
    getCustomer {
      id
      name
      email
      paymentMethod {
        id
        name
        email
        phone
        billingAddress {
          address1
          address2
          city
          stateProvinceRegion
          postalCode
          country
        }
        cardType
        cardLast4
      }
      activeSubscription {
        id
        status
        startDate
        periodStartDate
        periodEndDate
        planId
        usageSubscriptionItem
        plan {
          id
          name
        }
        usage
      }
      docApps {
        id
        lastModifiedDate
        size
      }
    }
  }
`;

const GET_CUSTOMER_SUBSCRIPTION_AND_PLANS = gql `
  query GetCustomerAndPlans {
    getCustomer {
      id
      name
      email
      paymentMethod {
        id
        name
        email
        phone
        billingAddress {
          address1
          address2
          city
          stateProvinceRegion
          postalCode
          country
        }
        cardType
        cardLast4
      }
      activeSubscription {
        id
        status
        startDate
        periodStartDate
        periodEndDate
        planId
        usageSubscriptionItem
        plan {
          id
          name
        }
        usage
      }
      docApps {
        id
        lastModifiedDate
        size
      }
    }
    listPlans {
      id
      name
      description
      image
      performance
      concurrency
      multiRegion
      numberOfApps
      dataConnectors
      support
      secretVault
      fileStorage
      sso
      advancedBillingOption
      watermarked
      monthlyFlatRate
      includedUsage
      additionalUsageFee
      prices {
        id
        billingType
      }
    }
  }
`;

const UPDATE_SUBSCRIPTION_PLAN_CHANGE = gql `
  mutation UpdateSubscription($planId: ID!) {
    updateSubscription(planId: $planId) {
      id
      name
      email
      paymentMethod {
        id
        name
        email
        phone
        billingAddress {
          address1
          address2
          city
          stateProvinceRegion
          postalCode
          country
        }
        cardType
        cardLast4
      }
      activeSubscription {
        id
        status
        startDate
        periodStartDate
        periodEndDate
        planId
        usageSubscriptionItem
        plan {
          id
          name
        }
        usage
      }
    }
  }
`;

const UPDATE_PAYMENT_METHOD = gql `
  mutation UpdatePaymentMethod($paymentMethodId: ID!) {
    updatePaymentMethod(paymentMethodId: $paymentMethodId)
  }
`;

const REMOVE_PAYMENT_METHOD = gql `
  mutation removePaymentMethod {
    removePaymentMethod
  }
`;

// 
const PaymentMethodForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [adminPageState, setAdminPageState] = useRecoilState(adminPageAtom);
  const [updateSubscription, {
    loading: updateSubscriptionLoading,
    error: updateSubscriptionError,
    data: updateSubscriptionData
  }] = useMutation(UPDATE_SUBSCRIPTION_PLAN_CHANGE);
  const [updatePaymentMethod, {
    loading: updatePaymentMethodLoading,
    error: updatePaymentMethodError,
    data: updatePaymentMethodData
  }] = useMutation(UPDATE_PAYMENT_METHOD, {
    // refetchQueries: GET_CUSTOMER
    onCompleted: (data) => {
      // If waiting for credit card to change subscriptions finish updating subscription
      const {updateSubscriptionOnPaymentMethod} = adminPageState;
      console.log("payment method updated: ", data);
      if (updateSubscriptionOnPaymentMethod) {
        updateSubscription({
          variables: {
            planId: updateSubscriptionOnPaymentMethod
          }
        });
      }
      
      setAdminPageState({
        ...adminPageState,
        showAddCreditCard: false,
        updateSubscriptionOnPaymentMethod: null
      });
    }
  });

  // 
  const handleUpdatePaymentMethod = async (event) => {
    event.preventDefault();

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement)
    });
    
    if(error) console.log('Payment Method Not Added: ', error);
    console.log('Payment Method: ', paymentMethod);

    // Send payment method ID to backend to link to customer
    updatePaymentMethod({
      variables: {
        paymentMethodId: paymentMethod.id
      },
      refetchQueries: () => [{
        query: GET_CUSTOMER,
      }],
    });
  };
  
  return (
    <form
      onSubmit={handleUpdatePaymentMethod}
    >
      <label>
        Card details
        <CardElement />
      </label>
      <button type="submit" disabled={!stripe}>Add Payment Method</button>
    </form>
  );
};



const AdminContent = () => {
  // Local State
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [username, setUsername] = React.useState('');
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [showChangePasswordForm, setShowChangePasswordForm] = React.useState(false);

  // Recoil state
  const [adminPageState, setAdminPageState] = useRecoilState(adminPageAtom);
  const [userState, setUserState] = useRecoilState(userAtom);

  // Graphql
  // console.log('test1 userStateVal: ', userState);
  const {
    loading: getCSPLoading,
    error: getCSPError,
    data: getCSPData,
    // refetch: refetchCSPData 
  } = useQuery(GET_CUSTOMER_SUBSCRIPTION_AND_PLANS);

  const [updateSubscription, {
    loading: updateSubscriptionLoading,
    error: updateSubscriptionError,
    data: updateSubscriptionData
  }] = useMutation(UPDATE_SUBSCRIPTION_PLAN_CHANGE);
  const [removePaymentMethod, {
    loading: removePaymentMethodLoading,
    error: removePaymentMethodError,
    data: removePaymentMethodData
  }] = useMutation(REMOVE_PAYMENT_METHOD, {
    refetchQueries: GET_CUSTOMER
  });

  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  const classes = useStyles();


  // 
  const handlePasswordUpdateForm = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log('userState: ', user);

      const foo = await Auth.changePassword(user, currentPassword, newPassword);
      // console.log('foo: ', foo);
      setShowChangePasswordForm(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  // 
  const handleCancelPasswordUpdateForm = () => {
    setShowChangePasswordForm(false);
    setUsername('');
    setCurrentPassword('');
  }

  // 
  const handleSubscriptionPlanChange = (planId) => {
    console.log('PlanId: ', planId);
    updateSubscription({
      variables: {
        planId: planId
      }
    });
  }

  // 
  const handleRequestForCreditCard = (planId) => {
    console.log('PlanId: ', planId);

    // Display add credit card layout
    // Set waiting for credit card
    setAdminPageState({
      ...adminPageState,
      showAddCreditCard: true,
      updateSubscriptionOnPaymentMethod: planId
    });
  };

  // 
  const handleRemovePaymentMethod = (event) => {
    event.preventDefault();

    removePaymentMethod({
      refetchQueries: () => [{
        query: GET_CUSTOMER,
      }]
    });
  }

  // 
  const handleAddPaymentMethod = () => {
    setAdminPageState({
      ...adminPageState,
      showAddCreditCard: true,
    });
  }

  return (
    <GridContainer justify="flex-start">  
      {/* Loading */}
      {!getCSPLoading ? 
        null :
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <div style = {{padding: '10px'}} >
              <div>Loading...</div>
            </div>
          </Card>
        </GridItem>
      }
      
      {/* Error */}
      {getCSPError ? `Error! ${getCSPError.message}` : null}

      {/* Data Loaded */}
      { getCSPData && !getCSPError ?
        <>
          {/* Left Column */}
          <GridItem xs={12} sm={12} md={3}>
            <Card className={classes[cardAnimaton]}>
              <div style={{padding: '10px'}}>
                <h3>User Details</h3>
                <div>User Email Address:</div>
                <p>{userState && userState.email}</p>
                {showChangePasswordForm ?
                  <>
                    <CustomInput
                      labelText="Current Password"
                      id="current-pass"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                        onChange: event => setCurrentPassword(event.target.value)
                      }}
                    />
                    <CustomInput
                      labelText="Password"
                      id="pass"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                        onChange: event => setNewPassword(event.target.value)
                      }}
                    />
                    <Button
                    color="success"
                    size="lg"
                    onClick={handlePasswordUpdateForm} >
                      Ok
                    </Button>
                    <Button
                    color="primary"
                    size="lg"
                    onClick={handleCancelPasswordUpdateForm} >
                      cancel
                    </Button>
                    <br />
                  </> :
                  <a onClick={() => setShowChangePasswordForm(true)}>Change Password</a>
                }
                <br />
                <br />
                <h3>Subscription Details</h3>
                <p>Subscription Status: {getCSPData.getCustomer.activeSubscription.status}</p>
                <p>Usage Count: {getCSPData.getCustomer.activeSubscription.usage}</p>
                <p>Start Date: {moment(getCSPData.getCustomer.activeSubscription.startDate).format('LL')}</p>
                {/* <p>Next Invoice Date: <a>{moment(getCSPData.getCustomer.activeSubscription.periodEndDate).format('LL')}</a></p> */}
                {/* <p><a>Invoice History</a></p> */}

                {/* <h3>Billing Details</h3>
                {getCSPData.getCustomer.paymentMethod ?
                  <>
                    <div>Payment Method: </div>
                    <div>Card Type: {getCSPData.getCustomer.paymentMethod.cardType}</div>
                    <div>Card: {getCSPData.getCustomer.paymentMethod.cardLast4}</div>
                    <div>Billing Zip: {getCSPData.getCustomer.paymentMethod.billingAddress.postalCode}</div> 
                    <br />
                    {getCSPData.getCustomer.activeSubscription.plan.name === 'Developer' ?
                      <button onClick={handleRemovePaymentMethod}>Remove Payment Method</button> :
                      null
                    }
                    <button onClick={handleAddPaymentMethod}>Update Payment Method</button>
                  </> :
                  <>
                    <p>Payment Method: N/A</p>
                    { !adminPageState.showAddCreditCard ?
                      <button onClick={handleAddPaymentMethod}>Add Payment Method</button> :
                      null
                    }
                  </>
                } */}
              </div>
            </Card>
          </GridItem>

          {/* Right Column */}
          <GridItem xs={12} sm={12} md={9}>
            {/* Available Plans */}
              <div style = {{padding: '10px'}} >
                <GridContainer justify="flex-start">
                  { getCSPData.listPlans.map(plan => {
                      return <GridItem xs={12} sm={12} md={3} key={plan.id}>
                        <Card  className={classes[cardAnimaton]}>
                          <CardHeader color="warning"><h4>{plan.name}</h4></CardHeader>
                          <CardBody style={{textAlign: 'center'}}>
                            {
                              plan.name === 'Developer' ? 
                                <>
                                  <h4 className={classes.cardTitle}>$0 / mo</h4>
                                  <div>Unlimited DocApps</div>
                                  <div>Unlimited Renders</div>
                                  <div>Standard Performance</div>
                                  <div>Watermarked</div>
                                  <br />
                                  <Button 
                                    color="primary" 
                                    // onClick={() => {
                                    //   !getCSPData.getCustomer.paymentMethod && plan.name !== 'Developer' ?
                                    //     handleRequestForCreditCard(plan.id) :
                                    //     handleSubscriptionPlanChange(plan.id)
                                    // }}
                                    // disabled={getCSPData.getCustomer.activeSubscription.planId === plan.id}
                                    disabled={true}
                                  >
                                    {getCSPData.getCustomer.activeSubscription.planId === plan.id ? 'Active' : 'Select'}
                                  </Button>
                                </> :
                                <>
                                  <h4 className={classes.cardTitle}>Coming Soon</h4>
                                </>
                            }
                            
                          </CardBody>
                        </Card>
                      </GridItem>
                    })
                  }

                  {/* Enterprise */}
                  <GridItem xs={12} sm={12} md={3} >
                    <Card className={classes[cardAnimaton]}>
                      <CardHeader color="warning"><h4>Enterprise</h4></CardHeader>
                      <CardBody>
                        <p>
                          For custom tailored enterprise features including SSO, multi-region support, additional concurrency and more, please contact our sales team.  
                        </p>
                        <Button 
                          color="primary" 
                          href="mailto:hello@emmodesign.com"
                        >Contact Us</Button>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
                
                
                {/* Display while working on updating the subscription */}
                { updateSubscriptionLoading ?
                  <div>Updating...</div> :
                  null
                }
              </div>
            
            {/* Add Credit Card */}
            { adminPageState.showAddCreditCard ?
              <Card className={classes[cardAnimaton]}>
                <div style = {{padding: '10px'}} >
                  <h3>Add Credit Card</h3>
                  <Elements stripe={stripePromise} >
                    <PaymentMethodForm />
                  </Elements>
                </div>
              </Card> :
              null
            }
            
            {/* DocApps */}
            <Card className={classes[cardAnimaton]}>
              <div style = {{padding: '10px'}} >
                <h3>DocApps</h3>
                {/* Display a getting started guide if no DocApps have been created yet*/}
                {getCSPData.getCustomer.docApps.length === 0 ? <GettingStarted /> : null }

                {/* Display a list of DocApps */}
                { getCSPData.getCustomer.docApps.map ( docApp => (<div key={docApp.id}><a>{docApp.id}</a></div>)) }
              </div>
            </Card>
          </GridItem>
        </> : 
        null
      }
    </GridContainer>
  )
}



export default function AdminPage(props) {

  // Recoil state
  const [userState] = useRecoilState(userAtom);

  
  const classes = useStyles();
  const { ...rest } = props;

  return (<>
    {/* Redirect to login when user is authenticated */}
    { userState === false ? 
      <Redirect to="/login" /> :
      <div>
        <Header
          absolute
          color="primary"
          rightLinks={<HeaderLinks />}
          {...rest}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          <div className={classes.container}>
            {/* If userState hasn't been determined yet don't display the Admin Content */}
            { userState !== null ? 
              <AdminContent /> :
              null
            }
          </div>
          <Footer whiteFont />
        </div>
      </div>
    }
  </>);
}
