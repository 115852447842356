import React from "react";
import { useRecoilState } from 'recoil';
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import GraphiQL from "graphiql";
import "graphiql/graphiql.min.css";
import "./GraphiqlPage.css";

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import userAtom from '../../state/userState';

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/landing-bg.jpg";

const useStyles = makeStyles(styles);

console.log('FOOD: ', process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT);

// GraphiQL component
export default (props) => {
    const [userState] = useRecoilState(userAtom);
    const classes = useStyles();
    const { ...rest } = props;

    const graphQLFetcher = (graphQLParams) => {
        return fetch(process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT, {
            method: "post",
            headers: { 
                "Content-Type": "application/json",
                "Authorization": userState ? userState.idToken : 'notAuthenticated',
            },
            body: JSON.stringify(graphQLParams)
        }).then(response => response.json());
    }
    
    const defaultQuery = `
        query GetCustomer {
            getCustomer {
                id
                name
                email
                docApps {
                id
                lastModifiedDate
                size
                }
            }
        }
    `;

    return (<>
        {/* Redirect to login when user is authenticated */}
        { userState === false ? 
            <Redirect to="/login" /> :
            <div>
                <Header
                    absolute
                    color="primary"
                    rightLinks={<HeaderLinks />}
                    {...rest}
                />
                <div
                    className={classes.pageHeader}
                    style={{
                    backgroundImage: "url(" + image + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "top center"
                    }}
                >
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem style={{height: '100vh'}} xs={12} sm={12} md={12}>
                                {/* If userState hasn't been determined yet don't display the Admin Content */}
                                { userState !== null ? 
                                    <GraphiQL 
                                        fetcher={graphQLFetcher} 
                                        defaultQuery={defaultQuery} 
                                        docExplorerOpen={false}
                                    /> :
                                    null
                                }
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
                <Footer whiteFont />
            </div>
        }
    </>);
  }